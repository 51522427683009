@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

/* @tailwind base; */
@tailwind utilities;
@tailwind components;

@layer components {
  .sidebar-icon {
    @apply relative flex items-center justify-center 
    h-12 w-12 mt-2 mb-2 mx-auto shadow-lg
    bg-gray-800 text-blue-500
    hover:bg-blue-600 hover:text-white
    rounded-3xl hover:rounded-xl
    transition-all duration-300 ease-linear
    cursor-pointer;
  }

  .sidebar-tooltip {
    @apply absolute w-auto p-2 m-2 min-w-max left-14
    rounded-md shadow-md text-white bg-gray-900
    text-xs font-bold transition-all duration-100
    scale-0 origin-left;
  }
}

:root {
  font-family: Montserrat;
  background-color: white;
}

body {
  margin: 0;
  display: flex;
  place-items: center;
  min-height: 100vh;
}

.chart-container {
  position: relative;
  width: 100%;
  padding-top: 60%;
}

.chart-container > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@keyframes scaling-squares-animation {
  50% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

@keyframes scaling-squares-spinner-animation-child-1 {
  50% {
    transform: translate(150%, 150%) scale(2, 2);
  }
}

@keyframes scaling-squares-spinner-animation-child-2 {
  50% {
    transform: translate(-150%, 150%) scale(2, 2);
  }
}

@keyframes scaling-squares-spinner-animation-child-3 {
  50% {
    transform: translate(-150%, -150%) scale(2, 2);
  }
}

@keyframes scaling-squares-spinner-animation-child-4 {
  50% {
    transform: translate(150%, -150%) scale(2, 2);
  }
}

.animate-scaling-squares {
  animation: scaling-squares-animation 1250ms infinite;
}
