.section {
  position: relative;
  padding: 40px 20px;
  background-color: #f2f8ff;
}

.card {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 250px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-self: center;
}

.heading {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
  color: #565656;
}

.highlight {
  color: white;
  background-color: #4d69e9;
  border-radius: 8px;
  padding: 2px 5px;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
}

.cardImage {
  width: 50%;
  max-width: 100%;
  height: auto;
  border-radius: 10px 10px 0 0;
}

.cardImage2 {
  width: 50%;
  max-width: 100%;
  margin-top: 25px;
  margin-bottom: 25px;
  height: auto;
  border-radius: 10px 10px 0 0;
}

.cardImage3 {
  width: 50%;
  max-width: 100%;
  top: 0;
  height: auto;
  border-radius: 10px 10px 0 0;
}

.cardImage4 {
  width: 35%;
  max-width: 100%;
  margin-top: 25px;
  margin-bottom: 20px;
  height: auto;
  border-radius: 10px 10px 0 0;
}

.cardContent {
  padding: 20px;
}

.cardHeading {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #666666;
  margin-top: 0;
  white-space: nowrap;
}

.cardText {
  margin: 0;
  color: #666666;
  font-size: 0.8rem;
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr 1fr;
    column-gap: 60px;
    margin: 10px;
  }

  .heading {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    color: #565656;
  }

  .card {
    width: 100%;
    height: auto;
    padding: 20px;
    justify-self: center;
  }

  .cardImage,
  .cardImage2,
  .cardImage3 {
    width: 100px;
    height: auto;
  }

  .cardImage4 {
    width: 80px;
    height: auto;
  }

  .cardContent {
    padding: 10px;
    text-align: center;
  }

  .cardHeading {
    font-size: 0.9rem;
  }

  .cardText {
    font-size: 0.7rem;
  }
}

@media (max-width: 480px) {
  .grid {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .card {
    width: 100%;
    padding: 15px;
  }

  .cardImage,
  .cardImage2 {
    width: 80px;
  }

  .cardContent {
    padding: 10px;
  }

  .cardHeading {
    font-size: 1.2rem;
  }

  .cardText {
    font-size: 1rem;
  }
}