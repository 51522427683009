
.disclaimerContainer {
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100%;
  }
  
  .checkboxLabel {
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    line-height: 1.4;
    text-align: left;
  }
  
  .checkbox {
    margin-right: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    border: 2px solid black;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    position: relative;
  }
  
  .checkbox:checked::before {
    content: "\2713";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: black;
  }
  
  .checkboxText {
    flex: 1;
    font-size: 9.5px;
  }
  
  .checkboxLabel {
    width: 100%;
    padding-left: 0;
    justify-content: flex-start;
  }
  
  .linksContainer {
    display: flex;
    justify-content:center;
    margin-bottom: 10px;
    gap: 50px;
  }
  
  .link {
    color: #666666;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
  }
  
  .link:hover {
    text-decoration: underline;
  }
  .disclaimerContainer-brandcut {
    margin-top: 20px;
    margin-bottom: 10px;
    width: 58%;
  }
   
  .checkboxLabel-brandcut {
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    line-height: 1.4;
    text-align: left;
  }
   
  .checkbox-brandcut{
    margin-right: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    border: 2px solid white;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    position: relative;
  }
   
  .checkbox-brandcut:checked::before {
    content: "\2713";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: white;
  }
   
  .checkboxText-brandcut {
    flex: 1;
    font-size: 9px;
    
  }
   
  .checkboxLabel-brandcut {
    width: 100%;
    padding-left: 0;
    justify-content: flex-start;
  }
   
  .linksContainer-brandcut {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    gap: 80px;
  }
   
  .link-brandcut {
    color: white;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
  }
   
  .link-brandcut:hover {
    text-decoration: underline;
  }
   

  .disclaimerContainer-fc {
    margin-top: 20px;
    margin-bottom: 10px;
    width: 58%;
  }
   
  .checkboxLabel-fc{
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    line-height: 1.4;
    text-align: left;
  }
   
  .checkbox-fc{
    margin-right: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    border: 2px solid white;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    position: relative;
  }
   
  .checkbox-fc:checked::before {
    content: "\2713";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: white;
  }
   
  .checkboxText-fc {
    flex: 1;
    font-size: 9px;
    color: white;
  }
   
  .checkboxLabel-fc {
    width: 100%;
    padding-left: 0;
    justify-content: flex-start;
  }
   
  .linksContainer-fc {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    gap: 50px;
  }
   
  .link-fc {
    color: white;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
  }
   
  .link-fc:hover {
    text-decoration: underline;
  }
   