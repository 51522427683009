.formOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.formContainer {
  background-color: #f0f4ff;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 400px;
  width: 100%;
  position: relative;
}

.closeButton {
  background: none;
  border: none;
  font-size: 24px;
  position: absolute;
  top: 10px;
  right: 5px;
  cursor: pointer;
}

.closeIcon {
  width: 25px;
  height: 25px;
}

h2 {
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
}

.highlight {
  color: #c62b6d;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 15px;
  font-size: 14px;
}

input {
  width: 94.6%;
  padding: 10px;
  margin-top: 5px;
  border-radius: 3px;
  border: 1px solid #ccc;
}

.contactInput {
  width: 77%;
  padding: 10px;
  margin-top: 5px;
  border-radius: 3px;
  border: 1px solid #ccc;
  font-family: inherit;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  position: relative;
  display: inline-block;
}

.submitButton {
  background-color: #c62b6d;
  color: white;
  border: none;
  margin-top: 20px;
  padding: 10px;
  border-radius: 3px;
  font-size: 16px;
  cursor: pointer;
  font-family: inherit;
  font-weight: 600;
  transition: all 0.3s;
}

.submitButton:hover {
  background-color: #f0f4ff;
  color: #c62b6d;
  outline: 1px solid #c62b6d;
  transition: all 0.3s;
}

.inputWrapper {
  position: relative;
}

.flagIcon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-35%);
  width: 20px;
  height: 20px;
}

.countryCode {
  position: absolute;
  left: 40px;
  top: 50%;
  transform: translateY(-35%);
  font-size: 14px;
}

.contactInput {
  padding-left: 80px;
}

.disclaimerContainer {
  margin-top: 20px;
  margin-bottom: 10px;
}

.checkboxLabel {
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  line-height: 1.4;
  text-align: left;
}

.checkbox {
  margin-right: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #c62b6d;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  position: relative;
}

.checkbox:checked::before {
  content: "\2713";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: #c62b6d;
}

.checkboxText {
  flex: 1;
  font-size: 12px;
}

.checkboxLabel {
  width: 100%;
  padding-left: 0;
  justify-content: flex-start;
}

.linksContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.link {
  color: #c62b6d;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

.submitButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.submitButton:disabled:hover {
  background-color: #cccccc;
  color: white;
  outline: none;
}

@media screen and (max-width: 480px) {
  .formContainer {
    margin: 20px;
  }

  input {
    width: 94%;
    padding: 10px;
    margin-top: 5px;
    border-radius: 3px;
    border: 1px solid #ccc;
  }

  .contactInput {
    width: 75%;
  }
}

@media screen and (max-width: 380px) {
  input {
    width: 93%;
    padding: 10px;
    margin-top: 5px;
    border-radius: 3px;
    border: 1px solid #ccc;
  }

  .contactInput {
    width: 72%;
  }
}

@media screen and (max-width: 320px) {
  h2 {
    font-size: 18px;
  }

  input {
    width: 92%;
    padding: 10px;
    margin-top: 5px;
    border-radius: 3px;
    border: 1px solid #ccc;
  }

  .contactInput {
    width: 68%;
  }
}
