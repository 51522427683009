.chatIcon {
  position: fixed;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 1000;
}

.iconImage {
  width: 80px;
  height: 80px;
}

@media (max-width: 480px) {
  .chatIcon {
    bottom: 1px;
    right: 1px;
  }
  .iconImage {
    width: 60px;
    height: 60px;
  }
}