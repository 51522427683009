

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 10px; 
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #d3d3d3; 
  border-radius: 10px; 
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #b0b0b0; 
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animate-scroll {
  animation: scroll 10s linear infinite;
}

.image-transition {
  transition: opacity 0.5s ease-in-out;
}



.triangle2 {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 100vw 200px 0;
  border-color: transparent transparent #EBF7FF transparent;
}


.disclaimerContainerworkshop {
  margin-top: 0px;
  margin-left: 0px;

}

.checkboxLabels {
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  line-height: 1.4;
  text-align: left;
  max-width: 40px;

}

.checkboxs {
  margin-right: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid white;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  position: relative;
}

.checkboxs:checked::before {
  content: "\2713";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: white;
}

.checkboxTextworkshop {
  flex: 1;
  font-size: 9.5px;
  padding-right: 80px;
  width:50%;
}

.checkboxLabels {
  width: 100%;
  margin-left: 40px;
  justify-content: flex-start;
}

.linksContainers {
  display: flex;
  justify-content:start;

  margin-bottom: 10px;
  gap: 80px;
}

.links {
  color: white;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
}

.links:hover {
  text-decoration: underline;
}

.linksContainers-mobile {
  display: flex;
  justify-content:start;

  margin-bottom: 10px;
  margin-left: 40px;
  gap: 40px;
}

.links-mobile {
  color: white;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
}

.links-mobile:hover {
  text-decoration: underline;
}