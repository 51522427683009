

@tailwind base;
@tailwind components;
@tailwind utilities;



@layer base {

  #root{
    @apply w-full
  }

 







 

}


