.sticky {
  position: sticky;
  top: 20px;
}
 
.sidebar {
  position: sticky;
  top: 0;
  text-align: left;
  height: calc(100vh - 8rem);
  display: flex;
  flex-direction: column;
}
 
.sidebar-header {
  padding: 1rem;
  background-color: #fff;
  z-index: 10;
  padding-top: 20px;
}
 
.sidebar-content {
  /* flex-grow: 1; */
  overflow-y: auto;
 
 
}
 
.headings-list {
  list-style-type: none;
  padding-top: 20px;
}
 
.heading-item {
  padding-left: 0;
  cursor: pointer;
  margin-bottom: 10px;
  transition: color 0.3s ease;
}
 
.heading-item.active {
  color: rgb(95, 191, 247);
}
 
.heading-item.level-1 { margin-left: 0px;}
.heading-item.level-2 { margin-left: 0px; }
.heading-item.level-3 { margin-left: 20px; }
.heading-item.level-4 { margin-left: 20px; }
.heading-item.level-5 { margin-left: 20px; }
.heading-item.level-6 { margin-left: 20px; }
 
@media (max-width: 768px) {
  .sidebar {
    position: static;
    height: auto;
  }
}
 
.sidebar-content::-webkit-scrollbar {
  display: none;
}
 
.sidebar-content {
  scrollbar-width: none;
  -ms-overflow-style: none;
}