/* 
.card-small {
 
}

.card-big {
  grid-row: span 3; 
}

.card-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr ;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 1.5rem; 
}

.card {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2fr 3fr;
}

    .gradient-background {
      position: relative;
      background-image: linear-gradient(225deg, #D37B9C 0%, #8EAEB7 100%);
      background-color: white;
      padding: 2rem;
      padding-top: 35rem;
      height: 30rem;
    }

@media screen and (max-width: 426px) {
  .card-grid {
    grid-template-columns: 1fr; 
    grid-template-rows: auto; 
    gap: 1rem;
  }

  .card-big,
  .card-small {
    grid-row: auto; 
  }


  .gradient-background {
    position: relative;
    background-image: linear-gradient(225deg, #D37B9C 0%, #8EAEB7 100%);
    background-color: white;
    padding: 5rem;


  }

} */








/* 

.card-big{
 
  height:100%;
  grid-row: span 2;
}

.card-small{

height:100%;
}

.card-grid{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr ;
  grid-template-rows: 1fr 1fr 1fr ;
  gap:2.4rem;
  height: 50rem
} */





.card-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: minmax(auto, max-content); /* Set auto rows based on content */
  row-gap: 2rem; /* Increase the gap between rows */

}

.card-big {
  height: auto; /* Remove the fixed height */
  grid-row: span 2; /* For 1st and 2nd indexes, span 2 rows */
}

.card-small {
  height: auto; /* Remove the fixed height */
}


/* Keep existing styles */

@media screen and (max-width: 768px) {
  .card-grid {
    grid-template-columns: 1fr; /* Change to single column layout on mobile */
  }
}
