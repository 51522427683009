  .section {
  text-align: center;
}

.button {
  padding: 15px 40px;
  background-color: #c62b6d;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 50px;
  margin-bottom: 50px;
  transition: 0.3s;
  font-weight: 700;
  font-size: 1.2rem;
  font-family: inherit;
  &:hover {
    background-color: white;
    color: #c62b6d;
    outline: 1px solid #c62b6d;
    transition: 0.3s;
  }
}

.heading {
  font-size: 2rem;
  margin-bottom: 40px;
  color: #666666;
  font-weight: 600;
}

.highlight {
  color: white;
  background-color: #4d69e9;
  border-radius: 8px;
  padding: 0 5px;
}

.imageGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  justify-items: center;
  background: #23389d;
}

.imageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.image {
  width: 300px;
  height: auto;
  background: white;
}

.textCard {
  background: #4d69e9;
  color: white;
  padding: 10px;
  width: 300px;
  box-sizing: border-box;
  transform: scale(0.8);
  transition: transform 1.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section.visible .textCard {
  transform: scale(1);
  opacity: 1;
}

.card1Text {
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
}

.card2Text {
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
}

.card3Text {
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
}

.card1 {
  grid-column: 1;
}

.card2 {
  grid-column: 2;
  margin-top: 80px;
}

.card3 {
  grid-column: 3;
}

.cardHeading {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.description {
  font-size: 1rem;
}
.section {
  text-align: center;
}

.button {
  padding: 15px 40px;
  background-color: #c62b6d;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 50px;
  margin-bottom: 50px;
  transition: 0.3s;
  font-weight: 700;
  font-size: 1.2rem;
  font-family: inherit;
  &:hover {
    background-color: white;
    color: #c62b6d;
    outline: 1px solid #c62b6d;
    transition: 0.3s;
  }
}

.heading {
  font-size: 2rem;
  margin-bottom: 40px;
  color: #666666;
  font-weight: 600;
}

.highlight {
  color: white;
  background-color: #4d69e9;
  border-radius: 8px;
  padding: 0 5px;
}

.imageGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  justify-items: center;
  background: #23389d;
}

.imageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.image {
  width: 300px;
  height: auto;
  background: white;
}

.textCard {
  background: #4d69e9;
  color: white;
  padding: 10px;
  width: 300px;
  box-sizing: border-box;
  transform: scale(0.8);
  transition: transform 1.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section.visible .textCard {
  transform: scale(1);
  opacity: 1;
}

.card1Text {
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
}

.card2Text {
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
}

.card3Text {
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
}

.card1 {
  grid-column: 1;
}

.card2 {
  grid-column: 2;
  margin-top: 80px;
}

.card3 {
  grid-column: 3;
}

.cardHeading {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.description {
  font-size: 1rem;
  padding: 0 10px;
}

@media (max-width: 768px) {
  .heading {
    font-size: 1.5rem;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .imageGrid {
    grid-template-columns: 1fr;
    gap: 20px;
    padding: 20px 0 20px 0;
  }

  .card1,
  .card2,
  .card3 {
    grid-column: span 1;
    margin-top: 0;
  }

  .card1 .image {
    border-radius: 35px 35px 0 0;
  }

  .card2 {
    display: flex;
    flex-direction: column;
  }

  .card2 .textCard {
    order: 2;
    border-radius: 0 0 35px 35px;
  }

  .card2 .image {
    order: 1;
    border-radius: 35px 35px 0 0;
  }

  .card3 .image {
    border-radius: 35px 35px 0 0;
  }
}
