.section {
  padding: 20px 20px;
  background-color: #23389d;
  color: #fff;
}

.heading {
  font-size: 2rem;
  color: white;
  text-align: center;
  font-weight: 600;
}

.highlight {
  color: #4d69e9;
  background: white;
  border-radius: 8px;
  padding: 0 5px;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.imageWrapper {
  position: relative;
  flex: 1;
  max-width: 500px;
  margin: 20px;
  margin-left: 100px
}

.pattern {
  position: absolute;
  margin-left: 200px;
  /* width: 400px;
  height: 400px; */
  z-index: 0;
}

.dashboard {
  position: relative;
  /* width: 480px;
  height: 430px; */
  border-radius: 10px;
  z-index: 1;
}

.features {
  flex: 1;
  max-width: 450px;
  margin: 20px;
  font-weight: 600;
  margin-right: 100px;
}

.features ol {
  padding: 0;
  list-style: none;
}

.features li {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  font-size: 1.2rem;
  position: relative;
  padding: 10px 20px;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0) 50%
  );
}

.button {
  padding: 15px 40px;
  background-color: white;
  color: #3e59bf;
  margin: 0 auto;
  display: block;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 50px;
  margin-bottom: 30px;
  transition: 0.3s;
  font-weight: 700;
  font-size: 1.2rem;
  font-family: inherit;
  &:hover {
    background-color: #23389d;
    color: white;
    outline: 1px solid white;
    transition: 0.3s;
  }
}

.particle {
  position: absolute;
  right: 0;
}

@media (max-width: 1280px) {
  .imageWrapper {
    margin-left: 40px;
  }

  .features {
    margin-right: 40px;
  }
}

@media (max-width: 768px) {
  .content {
    flex-direction: column;
    align-items: center;
  }

  .heading {
    font-size: 1.5rem;
    color: white;
    margin-bottom: 20px;
  }

  .imageWrapper {
    max-width: 100%;
    display: flex;
    justify-content: center;
    margin-left: 20px
  }

  .button {
    margin-top: 0;
  }

  /* .pattern {
    margin-left: 0;
    width: 80%;
    height: auto;
  } */
/* 
  .dashboard {
    width: 80%;
    height: auto;
  } */

  .features {
    max-width: 100%;
    margin: 20px 0;
    text-align: center;
    margin-right: 0;
  }

  .features li {
    font-size: 1.2rem;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0) 50%
    );
  }
}
