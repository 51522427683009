.section {
  position: relative;
  margin-top: -80px;
  padding: 40px 40px;
}

.card {
  background-color: #e8f0ff;
  padding: 80px 40px;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 20px;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}

.cardItem {
  /* background: linear-gradient(150deg, #152e99 10%, #2248f2 120%, #244cff 150%); */
  background: #3e59bf;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left;
  display: flex;
  align-items: center;
}

.cardImage {
  flex: 0 0 15%;
  max-width: 15%;
  height: auto;
  margin-right: 20px;
}

.cardImage2 {
  flex: 0 0 15%;
  max-width: 12%;
  margin-top: 12px;
  height: auto;
  margin-right: 20px;
}

.cardContent {
  flex: 1;
}

.cardHeading {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0 0 10px 0;
  color: white;
}

.cardText {
  margin: 0;
  color: white;
}

@media (max-width: 768px) {
  .section {
    padding: 40px 15px;
  }
  .card {
    padding: 40px 20px;
    margin: 0;
  }

  .grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .cardItem {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .cardImage,
  .cardImage2 {
    max-width: 50px;
    margin-bottom: 10px;
    margin-right: 0;
    align-self: center;
  }

  .cardContent {
    text-align: center;
  }

  .cardHeading {
    font-size: 1.4rem;
  }

  .cardText {
    font-size: 1.2rem;
  }
}
