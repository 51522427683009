.hero {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: linear-gradient(180deg, #ffffff 0%, #d0e3ff 120%);
 margin-top: 25px;
}

.content {
  max-width: 100%;
  color: black;
  justify-content: space-between;
  margin-top: 80px;
  
}

h1 {
  padding: 0px;
  font-size: 36px;
  color: #666666;
  font-weight: 500;
}

.button {
  padding: 15px 40px;
  background-color: #c62b6d;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 30px;
  margin-bottom: 30px;
  transition: 0.3s;
  font-weight: 700;
  font-size: 1.2rem;
  font-family: inherit;
  &:hover {
    background-color: #e8f0ff;
    color: #c62b6d;
    outline: 1px solid #c62b6d;
    transition: 0.3s;
  }
}

.image img {
  max-width: 700px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.logo {
  width: 250px;
  margin-bottom: 20px;
}

.resp {
  display: none;
}

@media screen and (max-width: 1024px) {
  .image img {
    max-width: 700px;
  }

  .hero {
    padding: 0 30px 20px;
  }
}

@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    align-items: center;
    padding: 60px;
    gap: 0;
  }

  .resp {
    display: block;
    width: 220px;
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .logo {
    display: none;
  }

  .image {
    order: 1;
    width: 100%;
    text-align: center;
  }

  .content {
    order: 2;
    text-align: center;
    margin-bottom: 0px;
    margin-right: 0;
  }

  .button {
    order: 3;
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }

  .logo {
    width: 200px;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .image img {
    width: 100%;
    margin: 0 auto;
  }

  h1 {
    font-size: 28px;
    margin-bottom: 0px;
    margin-top: 0px;
  }
}

@media (max-width: 480px) {
  .hero {
    flex-direction: column;
    align-items: center;
    padding: 10px;
    gap: 0;
  }

  .resp {
    display: block;
    width: 180px;
    margin-top: 70px;
    margin-bottom: 0px;
    position: absolute; /* or 'fixed' depending on your needs */
    left: 15px; 
    top: 5px;
}

  .logo {
    display: none;
  }

  .image {
    order: 1;
    width: 100%;
    text-align: center;
    margin-top: 80px;
  }

  .content {
    order: 2;
    text-align: center;
    margin-bottom: 0px;
    margin-right: 0;
  }

  .button {
    order: 3;
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }

  .logo {
    width: 200px;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .image img {
    width: 100%;
    margin: 0 auto;
  }

  h1 {
    font-size: 28px;
    margin-bottom: 0px;
    margin-top: 0px;
  }
}
