.section {
  padding: 40px 20px;
  text-align: center;
  background-color: #f2f8ff;
}

.heading {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #565656;
  font-weight: 600;
}

.highlight {
  color: white;
  background-color: #4d69e9;
  border-radius: 8px;
  padding: 2px 5px;
}

.highlight2 {
  display: none;
}

.subHeading {
  font-size: 1.5rem;
  margin-bottom: 30px;
  color: #3e59bf;
  font-weight: 500;
}

.imageGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  justify-items: center;
}

.outerCard {
  background-color: #fff;
  padding: 20px;
  padding-bottom: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 320px;
  
}

.imageCard {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.descHeading1,
.descHeading {
  font-size: 1rem;
  font-weight: bold;
  color: #666666;
  margin-top: 20px;
}

.description {
  font-size: 0.8rem;
  color: #666666;
  margin-top: 10px;
  text-align: center;
  white-space: nowrap;
}

.description2 {
  font-size: 0.7rem;
  color: #333;
  margin-top: 10px;
  text-align: center;
  white-space: nowrap;
}

.dots-container {
  text-align: center;
  margin-top: 10px;
}

.dot {
  display: none
}

.dot.active {
  background-color: #4d69e9;
}

@media (max-width: 1024px) {
  .imageGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .outerCard {
    width: 100%;
    max-width: 320px;
  }
}

@media (max-width: 768px) {
  .heading {
    font-size: 1.5rem;
  }

  .subHeading {
    font-size: 1.2rem;

  }
}

@media (max-width: 480px) {
  .imageGrid {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -ms-overflow-style: none;
    scrollbar-width: none;
    gap: 2;
    padding: 0;
    width: 90vw;
    box-sizing: border-box;
  }

  .imageGrid::-webkit-scrollbar {
    display: none;
  }

  .outerCard {
    flex: 0 0 100vw;
    scroll-snap-align: start;
    margin: 0;
  }
  
  .imageCard {
    padding: 10px;
  }

  .dots-container {
    margin-top: 20px;
  }

  .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: #bbb;
    border-radius: 50%;
    transition: background-color 0.3s;
    cursor: pointer;
  }

  .highlight {
    display: none;
  }

  .highlight2 {
    display: block;
    color: white;
    background-color: #4d69e9;
    border-radius: 8px;
  }

  .specialMargin {
    margin-top: 40px;
  }
  
  .descHeading1,
  .descHeading {
    font-size: 0.9rem;
  }

  .description {
    font-size: 0.8rem;
  }
}

@media (max-width: 320px) {
  .imageGrid {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    gap: 2;
    padding: 0;
    width: 110vw;
    box-sizing: border-box;
  }

  .outerCard {
    flex: 0 0 100vw;
    scroll-snap-align: start;
    margin: 0;
  }
  
  .imageCard {
    padding: 10px;
  }

  .dots-container {
    margin-top: 20px;
  }

  .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: #bbb;
    border-radius: 50%;
    transition: background-color 0.3s;
    cursor: pointer;
  }

  .highlight {
    display: none;
  }

  .highlight2 {
    display: block;
    color: white;
    background-color: #4d69e9;
    border-radius: 8px;
  }

  .specialMargin {
    margin-top: 40px;
  }
  
  .descHeading1,
  .descHeading {
    font-size: 0.9rem;
  }

  .description {
    font-size: 0.8rem;
  }
}