@keyframes fadeInWord {
  0% {
      opacity: 0;
      transform: translateY(20px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

.animate-fadeIn {
  animation: fadeIn 0.5s forwards;
}

.animate-fadeOut {
  animation: fadeOut 0.5s forwards;
}

.triangle3{
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 100vw 200px 0;
  border-color: transparent transparent white transparent;
}
.triangle4{
  position: absolute;
  bottom:-8px;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 198vw 400px 0;
  border-color: transparent transparent #FFEBF4 transparent;
}

.triangle5{
  position: absolute;
  bottom:-8px;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 198vw 400px 0;
  border-color: transparent transparent #EFECFF transparent;
}







.scrollbar-hide::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
.scrollbar-hide {
  -ms-overflow-style: none;  /* For Internet Explorer and Edge */
  scrollbar-width: none;  /* For Firefox */
}
