
.card-small {
 
}

.card-big {
  grid-row: span 3; 
}

.card-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1.5rem; 
}

.card {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2fr 3fr;
}

    .gradient-background {
      /* position: relative; */
      background-image: linear-gradient(225deg, #D37B9C 0%, #8EAEB7 100%);
      background-color: white;
      padding: 30px;
      padding-top: 30rem;
      height: 12rem;
    }
 
@media screen and (max-width: 426px) {
  .card-grid {
    grid-template-columns: 1fr; 
    grid-template-rows: auto; 
    gap: 1rem;
  }

  .card-big,
  .card-small {
    grid-row: auto; 
  }


  .gradient-background {
    position: relative;
    background-image: linear-gradient(225deg, #D37B9C 0%, #8EAEB7 100%);
    background-color: white;
    padding: 1rem;
    padding-top: 400px;
    


  }

}



.custom-code-block {
  background-color: #ffe6f2 !important; /* Light pink background */
  padding: 1rem !important; /* Some padding around the code */
  border-radius: 5px !important; /* Slightly rounded corners */
  overflow-x: auto !important; /* Handle long lines of code */
}

/* Optional: Customize the text color and other styles inside code blocks */
.custom-code-block code {
  color: #333; /* Dark text color */
  font-family: 'Courier New', Courier, monospace; /* Monospace font for code */
}
