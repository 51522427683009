.contactSection {
  display: flex;
  justify-content: space-between;
  padding: 40px 20px;
  background: linear-gradient(270deg, #4d69e9 0%, #738cff 94%);
  border-radius: 20px;
  margin-bottom: 40px;
  color: white;
  margin: 40px;
  align-items: center;
  position: relative;
}

.content {
  flex: 1;
  padding-left: 80px;
  z-index: 20;
}

.heading {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: left;
  padding: 0 4px;
  margin-left: 100px;
}

.description {
  font-size: 1.2rem;
  margin-left: 100px;
}

.poweredBy {
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 70px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.logo {
  width: 150px;
}

.form::before {
  content: "";
  position: absolute;
  width: 350px;
  height: 350px;
  border-radius: 100%;
  background: linear-gradient(
    90deg,
    rgba(77, 105, 233, 1) 0%,
    rgba(77, 105, 233, 0) 90%
  );
  left: 120px;
  top: 88px;
  z-index: -1;
}

.form {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  z-index: 10;
}

.input-container {
  position: relative;
  width: 100%;
  max-width: 300px;
}

.number-container {
  position: relative;
  width: 100%;
  max-width: 300px;
}

.input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: rgba(255, 255, 255, 0.2);
  font-weight: 500;
  font-family: inherit;
  color: white;
  position: relative;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  position: relative;
  display: inline-block;
  width: 300px;
}

placeholder {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 1rem;
  color: white;
  pointer-events: none;
  transition: 0.2s;
}

.input:focus + .placeholder,
.input:not(:placeholder-shown) + .placeholder {
  top: -10px;
  left: 10px;
  font-size: 0.8rem;
  color: white;
  background: transparent;
}

.button {
  width: 100%;
  max-width: 320px;
  padding: 10px;
  background-color: white;
  color: #4d69e9;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: 0.3s;
  outline: 1px solid white;
  font-family: inherit;
  margin-left: 20px;
  margin-top: 20px;
}

.button:hover {
  background-color: #4d69e9;
  color: white;
  transition: 0.3s;
}

.input::placeholder {
  color: white;
}

.error {
  color: darkred;
  font-size: 1rem;
  font-weight: bold;
}

.disclaimerContainer {
  margin-top: 20px;
  margin-bottom: 10px;
  width: 58%;
}

.checkboxLabel {
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  line-height: 1.4;
  text-align: left;
}

.checkbox {
  margin-right: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid white;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  position: relative;
}

.checkbox:checked::before {
  content: "\2713";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: white;
}

.checkboxText {
  flex: 1;
  font-size: 9px;
}

.checkboxLabel {
  width: 100%;
  padding-left: 0;
  justify-content: flex-start;
}

.linksContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 80px;
}

.link {
  color: white;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

.button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.button:disabled:hover {
  background-color: #cccccc;
  color: white;
  outline: none;
}

.disclaimerContainer {
  margin-top: 20px;
  margin-bottom: 10px;
  width: 74%;
}

@media screen and (max-width: 1440px) {
  .disclaimerContainer {
    width: 50%;
  }
}

@media screen and (max-width: 1280px) {
  .disclaimerContainer {
    width: 58%;
  }
}

@media screen and (max-width: 1024px) {
  .disclaimerContainer {
    width: 74%;
  }
}

@media screen and (max-width: 768px) {
  .section {
    margin: 20px;
    padding: 0px;
  }

  .heading {
    font-size: 1.5rem;
    text-align: center;
    margin-left: 0;
  }

  .description {
    text-align: center;
    margin-left: 0;
  }

  .poweredBy {
    margin-top: 20px;
    text-align: center;
    justify-content: center;
  }

  .contactSection {
    flex-direction: column;
    padding: 20px;
    margin: 20px;
  }

  .content {
    padding-left: 0;
    margin-bottom: 20px;
  }

  .form::before {
    display: none;
  }

  .disclaimerContainer {
    width: 45%;
  }
}

@media screen and (max-width: 480px) {
  .contactSection {
    flex-direction: column;
    text-align: center;
    padding: 35px 0;
    margin: 10px;
  }

  .placeholder {
    font-size: 1.2rem;
  }

  .input {
    position: relative;
    width: 90%;
    align-items: center;
  }

  .input-container {
    position: relative;
    width: 100%;
  }

  .number-container {
    position: relative;
    width: 67%;
    margin-right: 16px;
  }

  .description {
    font-size: 1.2rem;
    margin-left: 0;
  }

  .content {
    padding-left: 0;
    margin-bottom: 20px;
  }

  .button {
    width: 96%;
    max-width: none;
    margin-top: 10px;
    margin-right: 16px;
  }

  .heading {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 10px;
    margin-left: 0;
  }

  .form {
    gap: 15px;
  }

  .form::before {
    display: none;
  }

  .disclaimerContainer {
    width: 75%;
  }
}

@media screen and (max-width: 420px) {
  .heading {
    font-size: 1.5rem;
  }

  .description {
    font-size: 1rem;
  }

  .number-container {
    width: 77%;
  }

  .poweredBy {
    font-size: 1rem;
  }

  .button {
    font-size: 1rem;
  }

  .placeholder {
    font-size: 1rem;
  }

  .input {
    font-size: 1rem;
  }

  .error {
    font-size: 1rem;
  }

  .disclaimerContainer {
    width: 90%;
  }
}

@media screen and (max-width: 320px) {
  .number-container {
    width: 91%;
  }
}
